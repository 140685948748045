import React from 'react';
import styled from '@emotion/styled'
/*import colors from "styles/colors";*/
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';

const DefinitionsContainer = styled.div`
  position: relative;
  
  & + * {
    margin-top: 6.4rem;
  } 
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 12.8rem;
    }
  }
`;

const DefinitionsWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 84.8rem;
  margin: 0 auto;
  
  img {
    display: block;
    width: 100%;
    
    &.is-desktop {
      display: none;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    img {
      &.is-mobile {
        display: none;
      }
      
      &.is-desktop {
        display: block;
      }
    }
  }
`;

const Definitions = ({ desktop, mobile }) => {
  return (
    <DefinitionsContainer>
      <Inner>
        <DefinitionsWrap>
          {desktop && <img src={desktop.url} alt={desktop.alt} className='is-desktop'/>}
          {mobile && <img src={mobile.url} alt={mobile.alt} className='is-mobile'/>}
        </DefinitionsWrap>
      </Inner>
    </DefinitionsContainer>
  );
};

export default Definitions;
