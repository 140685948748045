import React from 'react';
import { graphql } from 'gatsby';
import { Inner } from 'styles/structure';
import Seo from 'components/Seo';
import Layout from 'components/Layout';
import Hero from 'components/Hero';
import ContentBlock from 'components/ContentBlock';
import Definitions from 'components/_page/about/Definitions';
import WeBelieve from 'components/_page/about/WeBelieve';
import History from 'components/_page/about/History';
import TrapdoorGrid from 'components/_grid/Trapdoor';
import TrapdoorCard from 'components/_card/Trapdoor';
import { withPreview } from 'gatsby-source-prismic'

const RenderBody = ({ about }) => (
  <>
    <Hero
      heading={about.hero_heading}
      text={about.hero_text}
      background={about.hero_background}
    />

    <ContentBlock
      content={about.introduction}
    />

    <Definitions
      mobile={about.definitions_mobile}
      desktop={about.definitions_desktop}
    />

    <WeBelieve
      background={about.we_believe_background}
      textBlocks={about.we_believe_content}
    />

    <History
      image={about.feature_image}
      content={about.feature_content}
    />

    <Inner>
      <TrapdoorGrid>
        {about.trapdoors.slice(0,2).map((card, i) => (
          <li>
            <TrapdoorCard
              key={i}
              image={card.hover_image}
              heading={card.heading}
              content={card.content}
              buttonText={card.button_text}
              destination={card.destination}
              variant='background'
            />
          </li>
        ))}
      </TrapdoorGrid>
    </Inner>
  </>
);

const AboutPage = ({ data }) => {
  //Required check for no data being returned
  const about = data.allPrismicAboutUs.edges[0].node.data;

  return (
    <Layout>
      <Seo
        title={about.social_title || 'About Us'}
        description={about.social_description}
        image={about.social_image}
      />

      <RenderBody about={about} />
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicAboutUs {
      edges {
        node {
          data {
            hero_heading {
              raw
            }
            hero_text
            hero_background {
              url
              alt
            }
            introduction {
              raw
            }
            definitions_desktop {
              url
              alt
            }
            definitions_mobile {
              url
              alt
            }
            we_believe_background {
              url
              alt
            }
            we_believe_content {
              text
            }
            feature_image {
              url
              alt
            }
            feature_content {
              raw
            }
            trapdoors {
              hover_image {
                url
                alt
              }
              heading {
                raw
              }
              content {
                raw
              }
              button_text
              destination
            }
          }
        }
      }
    }
  }
`;

export default withPreview(AboutPage);