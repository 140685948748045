import React from 'react';
import styled from '@emotion/styled'
/*import colors from "styles/colors";*/
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import FadeBlock from 'components/_page/about/FadeBlock';

const WeBelieveContainer = styled.div`
  position: relative;
  
  & + * {
    margin-top: 6.4rem;
  }
`;

const WeBelieveWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 84.8rem;
  margin: 0 auto;
  
  img {
    display: block;
    width: 100%;
    
    &.is-desktop {
      display: none;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    img {
      &.is-mobile {
        display: none;
      }
      
      &.is-desktop {
        display: block;
      }
    }
  }
`;


const WeBelieveBackground = styled.div`
  width: 100%;
  height: calc(100vh - 15.6rem);
  position: sticky;
  top: 20rem;
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top center;
  }
`;

const WeBelieveContent = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  padding-bottom: 50vh;
  margin-top: -50%;
`;

const WeBelieve = ({ background, textBlocks }) => {
  return (
    <WeBelieveContainer>
      <Inner>
        <WeBelieveWrap>
          <WeBelieveBackground>
            <img src={background.url} alt={background.alt}/>
          </WeBelieveBackground>

          <WeBelieveContent>
            {(textBlocks && textBlocks.length) && textBlocks.map((block, i) => (
              <FadeBlock
                key={i}
                text={block.text}
              />
            ))}
          </WeBelieveContent>
        </WeBelieveWrap>
      </Inner>
    </WeBelieveContainer>
  );
};

export default WeBelieve;
