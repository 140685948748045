import React from 'react';
import styled from '@emotion/styled'
import colors from "styles/colors";
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import ContentBlock from 'components/ContentBlock';

const HistoryContainer = styled.div`
  background-color: ${colors.grey100};
  padding: 4.8rem 0 6.4rem;
  
  & + * {
    margin-top: 6.4rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 8rem 0;
    
    & + * {
      margin-top: 8rem;
    }
  }
`;

const HistoryInner = styled(Inner)`
  & + * {
    margin-top: 5.6rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 10.4rem;
    }
  }
`;

const HistoryImage = styled.div`
  img {
    display: block;
    width: 100%;
  }
`;

const History = ({ image, content }) => {
  return (
    <HistoryContainer>
      <HistoryInner>
        {image && (
          <HistoryImage>
            <img src={image.url} alt={image.alt}/>
          </HistoryImage>
        )}
      </HistoryInner>

      {content && (
        <ContentBlock
          content={content}
          variant='history'
        />
      )}
    </HistoryContainer>
  );
};

export default History;
