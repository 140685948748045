import React, { Component } from 'react';
import styled from '@emotion/styled'
/*import colors from "styles/colors";*/
import dimensions from 'styles/dimensions';
import { InView } from 'react-intersection-observer';

const FadeBlockContainer = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.8s ease-in-out, visibility 0s 0.8s;
  
  & + * {
    margin-top: 60vh;
  }
  
  &.is-visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.8s ease-in-out, visibility 0s 0s;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
  
  }
`;

class FadeBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  onChange(inView, entry) {
    this.setState({
      visible: entry.isIntersecting,
    });
  }

  render() {
    return (
      <FadeBlockContainer className={this.state.visible && 'is-visible'}>
        <InView as="div" rootMargin='244px 0px 0px 0px' onChange={(inView, entry) => this.onChange(inView, entry)}>
          {this.props.text && <h1>{this.props.text}</h1>}
        </InView>
      </FadeBlockContainer>
    )
  }
}

export default FadeBlock;
